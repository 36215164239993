.button {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	height: fit-content;
	padding: 10px 24px;
	border-radius: 6px;
	text-align: center;
	font-family: 'DM Sans';
	font-size: 12px;
	font-weight: 700;
	text-wrap: wrap;
	border: none;
	outline: none;
	cursor: pointer;

	&,
	&:hover {
		background: @primary-color;
		border: 1px solid @primary-color;
		color: @white;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 6px;
		background-color: rgba(0, 0, 0, 0.2);
		opacity: 0;
		transition: 0.3s opacity;
	}

	&.loading {
		pointer-events: none;
		cursor: default;
	}

	&:hover {
		&:before {
			opacity: 1;
		}
	}

	&:disabled {
		background: #29292c !important;
		border-color: #29292c !important;
		color: rgba(255, 255, 255, 0.4) !important;
		-webkit-text-fill-color: rgba(255, 255, 255, 0.4) !important;
		box-shadow: none;
		cursor: default;

		&:before {
			display: none;
		}
	}
}

.button-primary {
	border-radius: 4px;
	box-shadow:
		0px 2px 4px 0px rgba(18, 16, 72, 0.5),
		0px 0px 5px 0px #0263f4 inset;

	&,
	&:hover {
		background: var(--Gradient-Blue, linear-gradient(270deg, #0267ff 0%, #004aba 90.5%));
		// border: 1px solid #4daaff;
		border: 1px solid #1467e4;
		color: @white;
	}
}

.button-border {
	border-radius: 4px;

	&,
	&:hover {
		background: transparent;
		border: 1px solid #139efb;
		color: #139efb;
	}
}

.button-text {
	padding: unset;
	height: fit-content;
	width: fit-content;

	&,
	&:hover {
		background: unset;
		border: unset;
		color: unset;
	}
}


@import '/workspace/src/styles/variables.less';