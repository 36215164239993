.telegram-login-widget {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 0;
	height: 0;
	overflow: hidden;

	iframe {
		width: 0;
		height: 0;
		overflow: hidden;
	}
}


@import '/workspace/src/styles/variables.less';