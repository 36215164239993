.input-container {
	width: 100%;
	position: relative;

	.input {
		width: 100%;
		padding: 12px 20px;
		border-radius: 6px;
		border: 1px solid #393c3f;

		&,
		input {
			background: @black;
			color: @white;
			font-size: 12px;
			font-weight: 700;
			box-shadow: none;
			outline: none;

			// white-space: nowrap;
			// overflow: hidden;
			// text-overflow: ellipsis;
		}

		&::placeholder,
		input::placeholder {
			color: #686868;
		}

		&:hover,
		&:focus,
		&:focus-within {
			border-color: var(--Gradient-Blue, #0267ff) !important;
		}

		&.inactive {
			border-radius: 4px;
			background: rgba(255, 255, 255, 0.1);
			border: none;
			pointer-events: none;
		}

		&.error {
			border-color: #f63030 !important;
		}

		[class~='ant-input-clear-icon'] {
			display: flex;
			align-items: center;
		}

		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		input::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		/* Firefox */
		&[type='number'],
		input[type='number'] {
			-moz-appearance: textfield;
		}
	}

	.icon-eye {
		position: absolute;
		top: 10px;
		right: 14px;
		cursor: pointer;
	}
}


@import '/workspace/src/styles/variables.less';