.animateSlideUp {
    opacity: 0;
}

.fadeIn {
    opacity: 0;
    -webkit-animation: slideUp 1s forwards;
    -webkit-animation-delay: 0;
    animation: slideUp 1s forwards;
    animation-delay: 0;
}
@keyframes slideUp {
    100% { 
        opacity: 1;
    }
}

.slideUp {
    transform: translateY(50px);
    opacity: 0;
    -webkit-animation: slideUp 1s forwards;
    -webkit-animation-delay: 0;
    animation: slideUp 1s forwards;
    animation-delay: 0;
}
@keyframes slideUp {
    100% { 
        transform: translateY(0);
        opacity: 1;
    }
}

.slideLeft {
    transform: translateX(50px);
    opacity: 0;
    -webkit-animation: slideUp 1s forwards;
    -webkit-animation-delay: 0;
    animation: slideUp 1s forwards;
    animation-delay: 0;
}
@keyframes slideUp {
    100% { 
        transform: translateX(0);
        opacity: 1;
    }
}

.widthGrow {
    width: 0;
    -webkit-animation: widthGrow 1.5s forwards;
    -webkit-animation-delay: 0;
    animation: widthGrow 1.5s forwards;
    animation-delay: 0;
}
@keyframes widthGrow {
    100% { 
        width: 100%;
    }
}