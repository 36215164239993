.overlay {
	background: rgba(0, 0, 0, 0.8) !important;
	backdrop-filter: blur(6px) !important;
}

.modal {
	max-width: 100% !important;
	padding: 0 !important;
	margin: 0 !important;
	background: transparent !important;
	box-shadow: none !important;
	border: 0 !important;
}

.wrap-auth {
	position: relative;

	button.button-back {
		background-color: transparent;
		border: none;
		padding: 0;
		clear: both;
		position: absolute;
		z-index: 1;
		left: 20px;
		top: 20px;
	}
	button.button-close {
		background-color: transparent;
		border: none;
		padding: 0;
		clear: both;
		position: absolute;
		z-index: 1;
		right: 20px;
		top: 20px;
	}
}


@import '/workspace/src/styles/variables.less';