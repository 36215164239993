.modal-mask {
	background: rgba(0, 0, 0, 0.8);
	-webkit-backdrop-filter: blur(6px);
	backdrop-filter: blur(6px);
}
.modal-wrap {
	padding: 20px;
}
.modal {
	max-width: 100% !important;
	width: fit-content !important;
}
.modal-content {
	max-width: 100%;
	margin: 0;
	color: @white;
	padding: 70px 70px 60px;
	overflow: auto;
	border-radius: 6px;
	border: 1px solid #5a5a5a;
	background: rgba(28, 30, 36, 0.8);
	box-shadow: 0px -2px 24px 0px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(12px);

	@media only screen and (max-width: (@screen-mobile-max)) {
		padding: 20px;
		padding-top: 57px;
	}

	[class~='ant-modal-close'] {
		position: absolute;
		top: 14px;
		right: 14px;
		color: @white;
	}
}
.modal-footer {
	display: none;
}

.loading-layer {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.55);
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}


@import '/workspace/src/styles/variables.less';