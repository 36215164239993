.overlay {
    background: rgba(0, 0, 0, 0.8) !important;
    backdrop-filter: blur(6px) !important;
}

.modal {
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
    border: 0 !important;
}


@import '/workspace/src/styles/variables.less';