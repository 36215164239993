.card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: fit-content;
	max-height: calc(100vh - 70px);
	height: fit-content;
	max-width: 100%;
	width: 326px;
	padding: 22px 17px 29px;
	margin: auto;
	border-radius: 11px;
	background: #1e1e1e;
	backdrop-filter: blur(2px);
	text-align: center;

	@media only screen and (max-width: (@screen-mobile-max)) {
		padding: 20px;
		padding-top: 57px;
	}
}

.search-wallet {
	width: 100%;
	margin: 20px 0;

	input {
		border: 1px solid #ffffff !important;
		background-color: transparent !important;
	}
}

.list-wallet {
	width: 100%;
	height: 298px;
	background-color: #060606;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 5px;
	}

	.dropdown-item {
		width: 100%;
		padding: 10px 12px;
		background: unset !important;
		border: none !important;
		background-color: unset;
		justify-content: flex-start;
		border: unset;
		border-radius: 0;
		text-align: left;
		gap: 0;

		&::before {
			display: none;
		}

		&:hover {
			background-color: rgba(125, 125, 125, 0.1) !important;
			opacity: 0.9;
		}

		&.active {
			background-color: #0099ff4d !important;
			border-radius: 2px;
			pointer-events: none;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 2px;
				background-color: #139efb;
				border-top-left-radius: 2px;
				border-bottom-left-radius: 2px;
			}
		}

		.icon {
			height: 30px;
			width: auto;
		}
	}
}

.btn-add {
	width: 100%;
	padding: 11px;
	border: 1px solid #139efb;
	color: #ffffff;
	font-size: 12px;
	font-weight: 700;
	border-radius: 49px;
	margin-top: 12px;
}

button.button-close {
	position: absolute;
	right: 17px;
	top: 20px;
	min-width: 26px !important;
	min-height: 26px !important;
	// border-radius: 50%;
	background-color: #2b2b2b;
	border: none;
	padding: 7px;
	clear: both;
}


@import '/workspace/src/styles/variables.less';