.card {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 565px;
	min-height: fit-content;
	max-height: calc(100vh - 70px);
	max-width: 100%;
	width: 360px;
	margin: auto;
	border-radius: 4px;
	border: 1px solid #383838;
	background: rgba(12, 12, 12, 0.4);
	backdrop-filter: blur(2px);

	line-height: 15.62px;

	.header,
	.body,
	.footer {
		padding: 15px 24px;

		@media only screen and (max-width: (@screen-sm)) {
			padding: 15px 20px;
		}
	}

	.header {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #ffffff33;

		& > div {
			width: 100%;
		}

		.arrow {
			display: flex;
			align-items: center;
			padding: 0 25px;
		}
	}

	.body {
		min-height: 399px;
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #ffffff33;
		border-left: unset;
		border-right: unset;
	}

	.footer {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.image {
		border-radius: 50%;
		object-fit: cover;
	}

	.border {
		padding: 17px;
		border: 1px solid #ffffff33;
		border-radius: 8px;
	}

	.funcName {
		width: fit-content;
		padding: 5px 7px;
		border: 1px solid #d9d9d9;
	}
}


@import '/workspace/src/styles/variables.less';