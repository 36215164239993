.card {
	display: flex;
	gap: 7px;
	padding: 13px 10px;
	border: 1px solid #ffffff33;
	border-left: 2px solid #d73847;
	border-radius: 8px;
	color: #ffffffcc;
	font-size: 12px;
	font-weight: 400;
	line-height: 15.62px;
	text-align: left;
}


@import '/workspace/src/styles/variables.less';